import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let SaveButton = class SaveButton extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.disabled = false;
    }
    async onPropDisabled(val) {
        this.disabled = val;
    }
    async changeLoadingStatus(status) {
        this.loading = status;
    }
    async voltar() {
        if (this.propVoltarRoute) {
            this.$router.push({ name: this.propVoltarRoute });
        }
        else {
            this.$router.back();
        }
    }
    async submit() {
        this.loading = true;
        this.$emit('submit');
    }
};
__decorate([
    Prop({ default: false })
], SaveButton.prototype, "propDisabled", void 0);
__decorate([
    Prop({ default: '' })
], SaveButton.prototype, "propTooltipMsg", void 0);
__decorate([
    Prop({ default: true })
], SaveButton.prototype, "propShowVoltar", void 0);
__decorate([
    Prop({ default: null })
], SaveButton.prototype, "propVoltarRoute", void 0);
__decorate([
    Prop({ type: String, default: 'salvar' })
], SaveButton.prototype, "propSubmitLabel", void 0);
__decorate([
    Watch('propDisabled')
], SaveButton.prototype, "onPropDisabled", null);
SaveButton = __decorate([
    Component
], SaveButton);
export default SaveButton;
