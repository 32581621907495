import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { salaStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newSala } from '@/interfaces/sala';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.selectedItem = {};
    }
    get salas() {
        return salaStore.salas;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            const result = await salaStore.updateSala(this.entityObject);
        }
        else {
            const result = await salaStore.createSala(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-salas' });
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await salaStore.getSala(parseInt(this.$router.currentRoute.params.id, 0));
        }
        else {
            this.entityObject = newSala();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
        },
    })
], Create);
export default Create;
